import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Text, css, IconButton } from 'theme-ui'
import { Link as GLink } from 'gatsby'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { FaRegStar,  FaPlus, FaHandHoldingHeart, FaHandHoldingMedical } from 'react-icons/fa'
import { setKtTokenSifre, logOut, getKtLogin } from '../../../../../../site/src/service/auth'
import { ModalContext } from '@solid-ui-components/Modal'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import ReactModal from 'react-modal'
import favoriPng from '../../../assets/favori-bicak-izi-pdf.png'
import FormInput3 from '@solid-ui-components/ContentForm/FormInput3'
import loadsGif from '../../../assets/loads.gif'


const styles = {
  wrapper: {
    flex: [`100%`, null, null, 1],
    minWidth: 280,
    maxWidth: [`none`, null, null, 280],
    minHeight: 325,
    maxHeight: [`none`, null, null, 325],
    cursor: `pointer`,
    p: 4
  },
  card: {

  },
  artic: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: `full`
  },

  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `200px`
  },

  searchL: {
    flex: [`100%`, null, null, 1],
    minWidth: 400,
  },

  searchR: {
    flex: [`100%`, null, null, 1],
    minWidth: 200,
    maxWidth: [`none`, null, null, 350],
    textAlign: `right`,
    marginTop: '0.5em'
  },
  textInp: {
    flex: [`100%`, null, null, 1],
    height: 50,
    backgroundColor: "white"
  },
  modalContent2: {
    borderRadius: `lg`,
    bg: `contentBg`,
    maxWidth: `container`,
    overflow: `hidden`,
    py: 4,
    px: 4
  },

}



const BlogBlock04 = ({ content: { Data }, ...props }) => {
  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const { setActiveModal } = useContext(ModalContext)

  const [btnID, setbtnID] = useState("");
  const [favoriModelId, setfavoriModelId] = useState(0);

  const formId = "frmFavori"
  const [modelListesi, setmodelListesi] = useState([]);


  const [ldg, setLdg] = useState(false);


  useEffect(() => {
    favListDoldur();
  }, [])

  function favListDoldur() {
    httpServiceToken.post("Locked/FavoriModelIcerikList", {}).then((res) => {
      if (res.DogruMu) {
        setmodelListesi(res.Data);
        setKtTokenSifre(res.Refresh);
        setLdg(true);
      } else {
        setLdg(true);
      }
    }).catch((err) => {
      setLdg(true);
    });
  }


  const onChanges = e => {
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value.replace(",", ".")
      }
    })
  }


  const onSubmit = e => {
    e.preventDefault();
    if (btnID.substring(0, 6) == "btnFav") {
      var favId = btnID.split("-")[1];
      var Secili = btnID.split("-")[2];
      var eklenecekMi = Secili == 'false' ? true : false;
      httpServiceToken.post("Locked/FavoriyeModelEkleCikar", { params: { modelId: favoriModelId, favoriId: favId, eklenecekMi: eklenecekMi } }).then((res) => {
        if (res.DogruMu) {
          setfavoriList(res.Data);
          setKtTokenSifre(res.Refresh);
        }
        favListDoldur();
      }).catch((err) => {
        let ss = "";
      });


    }

    if (btnID == "btnYeniFavoriEkle") {
      var favoriAdi = document.getElementById("txtFavoriAdi").value;

      if (favoriAdi == "" || favoriAdi == undefined || favoriAdi == null) {
        return;
      }
      httpServiceToken.post("Locked/FavoriEkle", { params: { modelId: favoriModelId, favoriAdi: favoriAdi } }).then((res) => {
        if (res.DogruMu) {
          setfavoriList(res.Data);
          setKtTokenSifre(res.Refresh);
        } else {

        }

        
        favListDoldur();
        setFormValues({
          ...formValues,
          [formId]: {
            ...formValues?.[formId]
          }
        })
        setbtnID("");
      }).catch((err) => {
        setbtnID("");
      });

    }
  }


  function favoriListesi(id) {
    setModalShow(false);
    setfavoriModelId(id);
    httpServiceToken.post("Locked/FavoriList", { params: id }).then((res) => {
      if (res.DogruMu) {
        setfavoriList(res.Data);
        setKtTokenSifre(res.Refresh);
        setModalShow(true);
      }
    }).catch((err) => {
      let a = 1;
    });

  }


  const [modalShow, setModalShow] = useState(false);
  const [favoriList, setfavoriList] = useState([]);


  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })

  }, [success, submitting, setFormValues])


  useEffect(async () => {
    if (typeof window !== 'undefined') {
      const ktLoginMiii = await getKtLogin();
      if (ktLoginMiii == true) {
      } else {
        logOut();
      }
    }
  }, []);

  return (
    <Container>
      {ldg == false ? <div className='dv-l'>
        <Divider space="3" />
        <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />
      </div> :
        <div>
          {modelListesi && (

modelListesi.length==0?
<>
<Divider space='5' />
      <h3 style={{ color: "black", textAlign: "center" }}></h3>
      <div style={{ marginLeft: "20%", marginRight: "20%", textAlign: "center" }} >
        <Text variant="h3" color="beta"> Hoş Geldiniz </Text>
        <Text color="black"  > Favor Listeniz Boş Görünüyor</Text>
        <Divider space='1' />
        <Text color="black"> Sık kullandığınız modelleri favorilerinize ekleyebilirsiniz</Text>
        <Divider space='1' />
        <Text color="black"> Dilediğiniz kadar favori listesi tanımlayabilirsiniz</Text>
        <Divider space='1' />
        <Text color="black"> Favori listesini kaldırmak için içerisindeki tüm modelleri favoriden çıkarmanız gerekmektedir</Text>
   
        <Box>
          <Text key={`lblicolcumesaj`} as='span' color='alpha' sx={{
          }} > <h1 onClick={e => {
            window.location.assign("/ambalaj-modelleri");
          }} style={{ cursor: "pointer", textDecoration: "underline" }}>
              {"Tüm Modellere Göz Atmak İçin Tıklayınız"} </h1>      </Text>
        </Box>
      </div>
      <Divider space='5' />
</>

            :modelListesi.map(
              ({ modelList, favoriBilgi }, ind) => (
                <Container key={`contndr-${ind}`}>
                  <Box>
                    <h1 className='benzer-cls'>{favoriBilgi.FavoriAdi}</h1>
                  </Box>
                  <Flex sx={{ flexWrap: `wrap`, justifyContent: `start`, m: -1 }}>
                    {modelList.map(
                      ({ SqlModel, CokBenzeyenGrupId, BuModelFavorimdeVarMi }, index) => (
                        <Box key={`ckb-${index}`} sx={styles.wrapper}>
                          <ContentContainer
                            content={{
                              variant: "cards.interactive",
                            }}
                            variant='cards.interactive'
                            sx={{
                              overflow: `hidden`,
                              height: `full`,
                              backgroundColor: "#E0E0E0",
                              borderStyle: "none"
                            }} >
                            <Flex as='article' sx={styles.artic}>
                              <>
                              <div className='cls-model-adi' onClick={() => {
                          localStorage.setItem("pM", SqlModel.Id);
                        }}>
                                <GLink className='cls-model-adi' to={"/bicak-izi-indir"}
                                   >
                                  <Box sx={{ flex: [0, 1], m: 2, mb: [null, null, null, 0] }}>
                                    <Box sx={styles.imageWrapper}>
                                      {
                                        <img src={window.location.href.includes('localhost') ? SqlModel.Model3DResimLinki : SqlModel.ModelResimLinki}
                                          alt={SqlModel.ModelHastag} style={{
                                            width: '100%',
                                            height: '100%',
                                          }} />
                                      }
                                    </Box>
                                    <Divider space={1} />
                                  </Box>
                                </GLink>
</div>
                                <Flex
                                  sx={{
                                    alignItems: `center`,
                                    justifyContent: `space-around`,

                                  }}

                                >
                                  <Text
                                    key={`mdls${SqlModel.Id}`}
                                    color="black"
                                    as="span"
                                  >

                                    {SqlModel.ModelAdi}

                                  </Text>

                                  <div onClick={async e => {
                                    e.preventDefault();
                                    const ktLoginMiii = await getKtLogin();
                                    if (ktLoginMiii == true) {
                                      favoriListesi(SqlModel.Id);

                                    }
                                    else {
                                      localStorage.setItem("623327ac7dc90a0020f891aa", "4");
                                      setActiveModal('authentication');

                                    }
                                  }}
                                  >
                                    <FaRegStar size={21} color="red" />
                                  </div>
                                </Flex></>
                            </Flex>
                          </ContentContainer>

                        </Box>
                      )
                    )}
                  </Flex>
                </Container>
              )))}

        </div>
      }
      <ReactModal
        id={"favoriteModal"}
        isOpen={modalShow}
        onRequestClose={() => setModalShow(false)}
        closeTimeoutMS={300}
        className='CustomModalPortalContent'
        overlayClassName='ModalPortalOverlay'
        shouldFocusAfterRender={false}
        sx={css({ ...styles.modalContent2 })}
        {...props}
      >
        <form
          onSubmit={onSubmit}
          method='POST'
        >

          <Flex
            sx={{
              alignItems: `center`,
              flexDirection: [
                `column`,
                `row`
              ]
            }}
          >
            <Box
              sx={{
                display: [`none`, null, `block`],
                flex: 1,
                minWidth: [null, null, null, 500],
                borderRadius: `left`,
                overflow: `hidden`
              }}
            >
              <img src={favoriPng} alt="online box die cutting loading" style={{ width: "500px", objectFit: "contain" }} />
            </Box>

            <Box sx={{ flex: 1, py: [3, 4], px: [3, 5] }}>

              {(favoriList && favoriList.length > 0) &&
                <Text
                  key={`lblfavs`}
                  variant={"h5"}
                  color="alpha"
                  mb='1'
                >
                  {"Favori Listem"}
                </Text>
              }
              {favoriList && favoriList.map(
                ({ FavoriAdi, Secili, Id }, ind) => (
                  <Box key={`lblfv${Id}`} >
                    <ContentContainer
                      content={{
                        variant: "cards.interactive",
                      }}
                      variant='cards.interactive'
                      sx={styles.card} >
                      <Flex
                        sx={{
                          alignItems: `start`,
                          justifyContent: `start`
                        }}  >

                        <Box sx={{ flex: 2, py: [2, 2], px: [3, 3] }}>
                          <Text key={FavoriAdi} as='span' color={Secili == false ? "black" : "red"}>
                            {FavoriAdi}
                          </Text> </Box>

                        <Box sx={{ flex: 1, py: [1, 1], px: [1, 1] }}>
                          <IconButton
                            type="submit"
                            id={"btnFav-" + Id + "-" + Secili}
                            onClick={e => setbtnID("btnFav-" + Id + "-" + Secili)}
                            aria-label='Ekle'
                            autoFocus
                            size="large"
                          >
                            {Secili == false ? <Container><FaHandHoldingMedical size={21} /> <Text>  Ekle</Text></Container> :
                              <Container> <FaHandHoldingHeart color='red' size={21} /> <Text color='red'>  Çıkar</Text> </Container>}
                          </IconButton>
                        </Box>
                      </Flex>
                    </ContentContainer>
                  </Box>
                ))}
              <Text
                key={`lblfavs`}
                variant={"h5"}
                color="alpha"
                mb='1'
              >
                {"Yeni Favori Listesi Oluştur"}
              </Text>
              <Flex
                sx={{
                  alignItems: `start`,
                  justifyContent: `start`
                }}  >

                <Box sx={{ flex: 2, py: [2, 2], px: [3, 3] }}>
                  <FormInput3
                    {...props}
                    onChange={onChanges}
                    name={"txtFavoriAdi"}
                    id={"txtFavoriAdi"}
                    value={formValues?.[formId]?.["txtFavoriAdi"] || undefined}
                    placeholder="Adını Giriniz"
                  /> </Box>

                <Box sx={{ flex: 1, py: [2, 2], px: [3, 3] }}>
                  <IconButton
                    type="submit"
                    id={"btnYeniFavoriEkle"}
                    onClick={e => setbtnID("btnYeniFavoriEkle")}
                    aria-label='Ekle'
                    autoFocus
                    size="large"
                  > <FaPlus size={21} />
                  </IconButton> </Box>
              </Flex>
            </Box>
          </Flex>
        </form>
      </ReactModal>
    </Container >
  )
}

export default WithDefaultContent(BlogBlock04)
